<template>

  <v-card
      dark
      :loading  = "this.$store.state.scan.qr_event_data.r"
      :disabled = "this.$store.state.scan.qr_event_data.r"
      class="mx-auto my-3"
      :max-width="getSettings.size.form_width"
  >
    <template slot="progress">
      <v-progress-linear
          color="orange"
          height="10"
          indeterminate
      ></v-progress-linear>
    </template>


    <v-card-title class="green darken">
      Статистика

      <v-spacer></v-spacer>

      <v-btn
          color="white"
          class="text--primary"
          fab
          x-small
          disabled
      >
        <v-icon>mdi-refresh</v-icon>
      </v-btn>
    </v-card-title>



    <v-virtual-scroll v-if="this.$store.state.scan.mongerList.b"
    :items="this.$store.state.scan.scan_statistics_local.event"
    :item-height="40"
    height="265"
    >

      <template v-slot:default="{ item }" class="ma-0 pa-0">

        <v-list-item class="ma-0">
          <v-list-item-avatar>
            <v-avatar
                :color="item.color"
                size="28"
                class="white--text caption"
            >
              {{ item.mark }}
            </v-avatar>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>{{ item.name }}</v-list-item-title>
          </v-list-item-content>

          <v-list-item-action>
            <v-btn
                depressed
                small
            >
              {{item.count}}/{{item.guess}}

              <v-icon
                  color="green lighten-1"
                  right
              >
                mdi-checkbox-marked-circle
              </v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </template>


    </v-virtual-scroll>


    <v-list-item class="ma-0">
      <v-list-item-avatar>
        <v-avatar
            color="red"
            size="28"
            class="white--text caption"
        >
          {{$store.state.scan.scan_statistics_local.total.mark}}
        </v-avatar>
      </v-list-item-avatar>

      <v-list-item-content>
        <v-list-item-title>{{$store.state.scan.scan_statistics_local.total.name}}</v-list-item-title>
      </v-list-item-content>

      <v-list-item-action>
        <v-btn
            depressed
            small
        >
          {{$store.state.scan.scan_statistics_local.total.count}}/{{$store.state.scan.scan_statistics_local.total.guess}}
          <v-icon
              color="orange darken-4"
              right
          >
            mdi-open-in-new
          </v-icon>
        </v-btn>
      </v-list-item-action>
    </v-list-item>






  </v-card>


</template>

<script>
import {mapGetters} from "vuex";

export default {

  name: "Statistics",

  computed: {

    ...mapGetters([
      "getSettings",
    ]),
  },
}
</script>

<style scoped>

</style>
